<template>
  <div class="AllPage">
    <div class="container">
    <h1>דומיין : {{this.SiteName}}</h1>
    <h6 v-if="this.CheckStatus(this.SiteName) === false">סטאטוס: <span class="Status">פנוי</span></h6>
    <h6 v-else>סטאטוס: <span class="NoStatus">לא פנוי</span></h6>
    <p v-if="this.CheckStatus(this.SiteName) === false">הדומיין "{{this.SiteName}}" פנוי , האם אתה מעוניין לרכוש אותו?</p>
    <section >
      <i class="bi bi-person Position"></i>
      <input class="InputContactUs" type="text" v-model="this.NameProp" placeholder="שם מלא">
    </section>
    <section>
      <i class="bi bi-phone Position"></i>
      <input class="InputContactUs" type="text" v-model="this.PhoneProp" placeholder="טלפון">
    </section>
    <section>
      <i class="bi bi-envelope Position"></i>
      <input class="InputContactUs" type="text" v-model="this.MailProp" placeholder="מייל (לא חובה)">
    </section>
    <button class="btn btn-primary" @click="this.SendForm()" v-if="this.SiteName != 'administrator' && this.SiteName != 'techlinx'">
      הצעת מחיר<i style="padding:0px 5px 0px 5px" class="bi bi-envelope"></i>
    </button>
  </div>
  </div>
  
</template>

<script>
import ProjectForms from './ProjectForms.vue';


export default {
  name: 'undefinedPage',
  components: {
  
  },
  data(){
    return{
      SiteName:'',
      NameProp:'',
      PhoneProp:'',
      MailProp:'',
      notAllowed:[
        'administrator',
        'login',
        'ProjectForms',
        'techlinx'
      ]
    }
  },
  mounted(){
    this.SiteName = this.$route.params.ProjectName

  },
  methods:{
    CheckStatus(RouteName){
      if(this.notAllowed.includes(RouteName)){
        return true
      }else{
        return false
      }
    },
    async SendForm(){
      var check = await this.CheckAll()
      if(check == true){
        console.log(this.NameProp)
        console.log(this.PhoneProp)
        console.log(this.MailProp)
      }else if(check == false){
        alert('אנא מלא את הטופס')
      }
      
    },
    CheckAll(){
      if(this.NameProp.length == 0 || this.PhoneProp == 0){
        return false
      }else{
        return true
      }
    }
  }
  
}
</script>

<style scoped>

.AllPage{
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  background: url('../assets/photo/background.jpg' ) no-repeat center center/cover;
}

.container{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  width: 550px !important;
  /* height: 70svh; */
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(350%) blur(5px);
  border-radius: 25px;
  padding: 60px 0px 60px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: Showing 2s ;
}
span.Status{
  color: rgb(0, 149, 0);
}
span.NoStatus{
  color: rgb(149, 0, 0);
}

section{
  position: relative;
  width: 70%;
  display: flex;
}

input.InputContactUs{
  outline: none;
  border: none;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
  padding: 10px 35px 10px 10px;
  width: 100%;
}

i.Position{
  position: absolute;
  right: 0;
  font-size: 22px;
  padding: 7px;
}

@keyframes Showing {
  from{
    opacity:0
  }
  to{
    opacity: 1;
  }
}

@media (max-width:800px){
  .container{
    position: relative;
    right: 0;
    width: 80% !important;
  }
}

</style>
