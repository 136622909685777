<template>
  <div class="AdminContainer" v-if="this.user">
    <NavbarRight @Div-Open="this.OpenDiv"/>
    <div class="ContextDiv">
      <h1 v-if="this.HomeData.length == 0 && this.Projects.length == 0 && this.ContactData.length == 0">מעלה נתונים</h1>
      <HomeEdit v-if="this.HomeDiv && this.HomeData.length != 0" :HomeData="this.HomeData"/>
      <ProjectsEdit v-if="this.ProjectsDiv && this.Projects.length != 0" :Projects="this.Projects"/>
      <ContactEdit v-if="this.ContactDiv && this.ContactData.length != 0" :ContactData="this.ContactData"/>
      <PermissionEdit v-if="this.PermissionDiv"/>
    </div>
  </div>
  
  


</template>

<script>

import axios from 'axios'
import NavbarRight from '@/components/NavbarRight.vue'
import HomeEdit from '@/view/AdminView/HomeEdit.vue'
import ProjectsEdit from '@/view/AdminView/ProjectsEdit.vue'
import ContactEdit from '@/view/AdminView/ContactEdit.vue'
import PermissionEdit from '@/view/AdminView/PermissionEdit.vue'
import { useRoute ,useRouter} from 'vue-router';



export default {
  name: 'administrator',
  components: {
    NavbarRight,
    HomeEdit,
    ProjectsEdit,
    ContactEdit,
    PermissionEdit
  },
  data(){
      return{
        HomeData:[],
        Projects:[],
        ContactData:[],
        SiteName:'',
        user:null,

        // div replacement s
        HomeDiv:true,
        ProjectsDiv:false,
        ContactDiv:false,
        PermissionDiv:false,



      }  
  },
  created(){
    
  },
  mounted(){
    this.SiteName = this.$route.path
    this.SiteName = this.SiteName.replace('/', '')
    console.log(this.SiteName)
    this.userData()
    
    
  },
  methods: {
    async GetData(){
      

      const Home = axios.get('/.netlify/functions/GetDataHome');
      const Projects = axios.get('/.netlify/functions/GetDataProjects');
      const Contact = axios.get('/.netlify/functions/GetDataContact');
      // you could also use destructuring to have an array of responses
      await axios.all([Home, Projects, Contact]).then(axios.spread((Home, Projects, Contact) => {
      this.HomeData = Home.data
      this.Projects = Projects.data
      this.ContactData = Contact.data
      console.log(this.ContactData)
      }));

    },
    async userData(){
      const router = useRouter();
      var token = localStorage.getItem("token"); // Replace this with the actual token

      await axios
        .get('/.netlify/functions/user-data', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            WebsiteNameProp: this.SiteName,
          }
        })
        .then(response => {
          console.log(response.data)
          this.user = response.data
          this.GetData()
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          router.push('/')
        });
    },
    OpenDiv(event){
      this.ResetDivs()
      if(event == 'Home'){
        this.HomeDiv = true
      }else if(event == 'Projects'){
        this.ProjectsDiv = true
      }else if(event == 'Contact'){
        this.ContactDiv = true
      }else if(event == 'Permission'){
        this.PermissionDiv = true
      }
    },
    ResetDivs(){
      this.HomeDiv = false
      this.ProjectsDiv = false
      this.ContactDiv = false
      this.PermissionDiv = false
    }
  }  
}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.AdminContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.ContextDiv{
  border: 1px solid none;
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  color: white;
  direction: rtl;
  z-index: 100;
  background: #5b97c9c2;  
  /* background: linear-gradient(to left, rgb(12, 30, 79), #83c7ff ); */

}


.ContextDiv h1{
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%);
}

.ContextDiv h1{

  &::after{
    font-size: 20px;
    animation: dots 1.2s linear infinite;
    content: "";
  }
}

@keyframes dots{
  0%{
    content: ".";
  }
  50%{
    content: "..";
  }
  100%{
    content: "...";
  }
}
@media (max-width: 750px) {
  .AdminContainer{
    display: flex;
    flex-direction: column;
    justify-content: none;
    align-items: none;
  }
  .ContextDiv{
  border: 1px solid none;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 80svh;
  color: white;
  direction: rtl;
  z-index: 100;
  background: #5b97c9c2;  
  /* background: linear-gradient(to left, rgb(12, 30, 79), #83c7ff ); */

}
}



</style>




