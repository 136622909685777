<template>
  <div class="navbarContainer">
    <div class="LogoImg">
      <img src="@/assets/Logo/logo2.png" alt="">
    </div>
    <div class="Menu">
      <label class="TitleOptions">דפים</label>
      <span class="ButtonSelect" @click="this.OpenDiv('Home')"><span><i class="bi bi-house-door IconsButton"></i></span><span class="TitleMenu">דף הבית</span></span>
      <span class="ButtonSelect" @click="this.OpenDiv('Projects')"><span><i class="bi bi-code-slash IconsButton"></i></span><span class="TitleMenu">פרוייקטים</span></span>
      <span class="ButtonSelect" @click="this.OpenDiv('Contact')"><span><i class="bi bi-person IconsButton"></i></span><span class="TitleMenu">אודות</span></span>

      <label class="TitleOptions">ניהול מערכת</label>
      <span class="ButtonSelect" @click="this.OpenDiv('Permission')"><span><i class="bi bi-shield-lock IconsButton"></i></span><span class="TitleMenu">הרשאות</span></span>
    </div>
  </div>
  

</template>

<script>

import axios from 'axios'


export default {
  name: 'NavbarRight',
  components: {
 
  },
  data(){
      return{

      }  
  },
  created(){
    
  },
  mounted(){

  },
  methods: {
    OpenDiv(event){
      // console.log(event)
      this.$emit('Div-Open' , event);
    }
  }
  
}
</script>

<style scoped>


/* ----------- navbar Width Screen ------------ */

.navbarContainer{
  border: 1px solid none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px !important;
  height: 100vh;
  color: white;
  direction: rtl;
  z-index: 100;
  background: linear-gradient(to right, rgb(12, 30, 79), rgb(10, 11, 19));
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.3); 
}

.LogoImg{
  border: 1px solid none;
  position: relative;
  width: 100%;
  height: 20%;
}
.LogoImg img{
  width: 100%;
}

.Menu{
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid none;
  width: 100%;
  height: auto;
  margin:20px 0 0 0;
  padding: 10px 0px 0 0 ;
}

label.TitleOptions{
  color: #777;
  padding:30px 20px 15px 0px;
}

span.ButtonSelect{
  border: 1px solid none;
  padding:10px 20px 10px 0px;
  cursor: pointer;
}

span.ButtonSelect:hover{
  background-color: blue;
}

i.IconsButton{
  padding: 0px 0px 0px 10px;

}

@media (max-width: 750px) {
  .navbarContainer{

    flex-direction: row;
    width: 100% !important;
    height: 20svh;

  }
  .LogoImg{
 
    width: 200px;
  }
  .LogoImg img{
    width: 100%;
  }
  .Menu{
    height: 100%;
    flex-direction: row;
  }
  label.TitleOptions{
    display: none;
  }
  span.ButtonSelect{
    width: fit-content;
    height: fit-content;
  }
  span.ButtonSelect:hover{
    background-color: transparent;
    font-weight: bold;
  }
  span.TitleMenu{
    display: none;
  }
}



</style>




