<template>
  <div class="Circle">
    <img class="PortraitImg" :src="this.ImgUrl" v-if="this.ImgUrl">
  </div>

</template>

<script>

import axios from 'axios'


export default {
  name: 'Portrait',
  props:['ImgUrl'],
  components: {
 
  },
  data(){
      return{
      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {

  }
  
}
</script>

<style scoped>

/* ----------------- Circle -------------------- */

.Circle{
  border: 1px solid rgb(12, 30, 79);
  /* border-radius: 73% 27% 54% 46% / 32% 98% 2% 68%; */
  /* border-radius: 23% 77% 93% 7% / 81% 67% 33% 19%; */
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  border-bottom: 1px solid none;
  width: 100%;
  overflow: hidden;
  background-color: rgb(187, 228, 255);
  box-shadow: 0 10px 20px rgba(30, 121, 241, 0.5), /* Blue glow effect on the bottom */
  0 10px 20px rgba(30, 121, 241, 0.5);
}


/* ----------------- img ----------------------- */

img.PortraitImg{
  width: 100%;
  animation: showingMedia 0.6s ease-in;

}


@keyframes showingMedia {
  0% 
  {
    opacity:0;
  }
  100% 
  {
    opacity: 1;
  }
}

</style>




