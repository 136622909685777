<template>
<div class="AllContainerAdd">
  <div class="InfoDiv">
    <div class="TextDiv">
      <label for="">שם פרוייקט:</label>
      <input type="text" :value="this.TitlePost" @input="TitleUpdate = $event.target.value">
    </div>
    <div class="TextDiv">
      <label for="">כתובת Url:</label>
      <input style="direction: ltr;" type="url" :value="this.UrlPost" @input="UrlUpdate = $event.target.value">
    </div>
    <div class="TextDiv">
      <label for="">תיאור:</label>
      <textarea name="" id="" cols="20" rows="7" :value="this.InfoPost" @input="InfoUpdate = $event.target.value"></textarea>
    </div>
    
    
  </div>
  <div class="ImgDiv">
    <img :src="this.ImgUpdate" alt="">
    <button class="btn ChangeButton" @click="this.openWigit"><i class="bi bi-upload"></i></button>
    <div class="ButtonsDiv">
    <button class="btn btn-success BtnClass" @click="this.PostProject()"><i class="bi bi-check2"></i></button>
    <button class="btn btn-danger BtnClass" @click="this.EndEdit()"><i class="bi bi-x"></i></button>

  </div>
  </div>

</div>
  <checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
    :DataPost="this.DataPost" :successProp="this.successProp" /> 

</template>

<script>

import axios from 'axios'
import checkComponent from '@/components/checkComponent.vue'



export default {
  name: 'EditProject',
  emits: ['EndEdit'], 
  props:['EditProp'],
  components: {
    checkComponent
  },
  data(){
      return{
        
        TitlePost:'',
        ImgPost:'',
        UrlPost:'',
        InfoPost:'',

        TitleUpdate:'',
        ImgUpdate:'',
        UrlUpdate:'',
        InfoUpdate:'',

        // loading Function
        LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,

      }  
  },
  created(){
    
  },
  mounted(){
    this.SetData()
  },
  methods: {
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
          window.location.reload()
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },
    EndEdit(){
      this.$emit('endEdit')
    },
    SetData(){
      this.TitlePost = this.EditProp.Title
      this.ImgPost = this.EditProp.Img
      this.UrlPost = this.EditProp.Url
      this.InfoPost = this.EditProp.Info

      this.TitleUpdate = this.EditProp.Title
      this.ImgUpdate = this.EditProp.Img
      this.UrlUpdate = this.EditProp.Url
      this.InfoUpdate = this.EditProp.Info
    },  
      async PostProject(){
        

        var ResultLength = await this.CheckLength()
        var ResultUpdate = await this.CheckUpdate()

          if(ResultLength == true && ResultUpdate == true){
            // Start Loading...
            this.LoadingDone = false
            this.LoadingAllow = true

            const RequestData = {
              Data: { 
                Img:this.ImgUpdate,
                Url:this.UrlUpdate,
                Title:this.TitleUpdate,
                Info:this.InfoUpdate, 
              },
              Collection:{
                Name:'Projects'
              },
              Id:this.EditProp.Id,
            };
            
            await axios.post(`/.netlify/functions/UpdateProject`, RequestData).then(response => {
              console.log(response);
              this.SuccessPost('העריכה בוצעה')
            }).catch(error => {
                console.log(error);
                this.ErrorPost('העריכה נכשלה')
            }); 

          }else if(ResultLength == false){
            alert('אנא מלא את כל הפרטים!')
          }else if(ResultUpdate == false){
            alert('שום דבר לא השתנה!')
          }
    },
    CheckLength(){
      if(this.ImgUpdate.length != 0 && this.UrlUpdate.length != 0 && this.TitleUpdate.length != 0 && this.InfoUpdate.length != 0 ){
        return true
      }else{
        return false
      }
    },
    CheckUpdate(){
      if(this.ImgPost != this.ImgUpdate || this.UrlPost != this.UrlUpdate || this.TitlePost != this.TitleUpdate || this.InfoPost != this.InfoUpdate ){
        return true
      }else{
        return false
      }
    },
    openWigit(){

      const widget = window.cloudinary.createUploadWidget(
        {cloud_name:"dfnusjlhc", upload_preset: "TechLinx"},
        (error,result)=>{
          if(!error && result && result.event == "success"){
            console.log("Done uploading ....",result.info.url)
            this.ImgUpdate = result.info.url   
          }
        }
      )
      widget.open()
    },
  }
  
}
</script>

<style scoped>

/* add Sections */

.AllContainerAdd{
  border: 1px solid none;
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 0px;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  
}


.InfoDiv{
  border:1px solid none;
  position: relative;
  justify-content: center;
  width: 45%;
  height: fit-content;
  text-align: center;
  padding: 0px 0px 10px 0px;
  border-radius: 15px;
  /* background: #c0e3ff;   */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.TextDiv{
  display: flex;
  justify-content: space-between
}

.TextDiv label{
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1.3vw;
}

.TextDiv input,.TextDiv textarea{
  width: 60%;
  margin: 5px 0px 5px 0px;
  font-size: .8em;

}

.TextDiv textarea{
  height: 11vw;
}

.ImgDiv{
  border:1px solid none;
  position: relative;
  width: 40%;
  height: fit-content;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.ImgDiv img{
  /* border: 1px solid #6385a1;  */
  width: 100%;
  height: 16.5vw;
  border-radius: 15px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.ChangeButton{
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px 5px 10px;
  font-size: 1vw;
}

.ChangeButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-size: 22px;
  font-weight: bold;
}

.SubmitButton{
  padding: 5px 10px 5px 10px;
  margin: 10% 0% 0% 0%;
  font-size: 1vw;
  
}

.SubmitButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-weight: bold;
}

.ButtonsDiv{
  border: 1px solid none;
  padding: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}


@media (max-width: 750px) {
  .TextDiv label{
    color: #101F38;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1.2em;
  }
  .AllContainerAdd{
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    overflow: auto;
  }
  .ImgDiv{
    border: 1px solid none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
  .ImgDiv img{
    /* border: 1px solid #6385a1;  */
    width: 100%;
    height: 15vh;
    object-fit: contain;

  }
  .InfoDiv{
    width: 80%;

  }
  .SubmitButton{
    font-size: .7em;
  }
  .TextDiv input,.TextDiv textarea{
    width: 50%;
    margin: 5px 0px 5px 0px;
    font-size: .6em;
  }

  .TextDiv textarea{
    height: 150px;
  }
  
}


</style>




