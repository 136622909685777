<template>
  <div class="AllContainer" v-show="this.LoadingAllow == true">
    <div class="SuccessDiv"  v-show="this.LoadingDone == false">
      <div class="LoadingDiv"></div>
      <span class="DotSpan"></span>
    </div>
    <div class="SuccessDiv"  v-show="this.LoadingDone == true  & this.DataPost == true">
      <div class="divImg"><img src="@/assets/video/success.gif" alt=""></div>
      <span>{{ this.successProp }} בהצלחה !</span>
    </div>
    <div class="SuccessDiv"  v-show="this.LoadingDone == true & this.DataPost == false">
      <div class="divImg"><img src="@/assets/video/failed.gif" alt=""></div>
      <span>{{ this.successProp }} , אנא נסה שנית </span>
    </div>   
  </div>
</template>

<script>

export default {
  name: 'checkComponent',
  props:['LoadingAllow','LoadingDone','DataPost','successProp'],
  data(){
    return{
    }
  },

}
</script>

<style scoped>


.AllContainer{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  direction: rtl !important;
  background: linear-gradient(
    rgba(0,0,0,0.8),
    rgba(0,0,0,0.8)
  );
  z-index: 999999999;
}
.AllContainer .divImg img{
  width: 200px !important;
  height: 100px;
}
.LoadingDiv{
  width: 100px !important;
  height: 100px;
  border: 10px solid #ccc;
  border-right-color: #673ab7;
  border-radius: 50%;
  animation: rotate 1.2s linear infinite;
}

.AllContainer span{
  color: white;
}

.SuccessDiv{
  align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.AllContainer span.DotSpan{

  &::after{
    font-size: 20px;
    animation: dots 1.2s linear infinite;
    content: "מעלה נתונים";
  }
}

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}
@keyframes dots{
  0%{
    content: ".";
  }
  50%{
    content: "..";
  }
  100%{
    content: "...";
  }
}

</style>
