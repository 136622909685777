<template>
  <h1>תיק עבודות</h1>
  
  <!-- <carousel :cards="this.cards" v-if="this.cards.length > 0"/> -->
    <carousel :cards="this.cards" />

</template>

<script>

import carousel from '@/components/carousel.vue'

export default {
  name: 'ProjectsComponent',
  props:['cards'],
  components: {
    carousel
  },
  data(){
    return{
      // cards:[{},{},{},{},{},{}]
    }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
  
  }
}
</script>

<style scoped>
h1{
  position: absolute;
  top: 5%;
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

</style>




