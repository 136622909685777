<template>
  <div class="CardContainer" @mouseover="this.EditDiv = true" @mouseleave="this.EditDiv = false">
    <img :src="this.IMGProject">
    <div class="EditDiv" v-if="this.EditDiv">
      <button class="btn btn-danger" @click="this.DeletePoject()"><i class="bi bi-trash"></i></button>
      <a :href="this.URLProject"><button class="btn btn-dark"><i class="bi bi-link"></i></button></a>
      <button class="btn btn-primary" @click="this.EditProject()"><i class="bi bi-pencil-square"></i></button>
    </div>
  </div>
  <checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
    :DataPost="this.DataPost" :successProp="this.successProp" /> 

</template>

<script>

import axios from 'axios'
import checkComponent from '@/components/checkComponent.vue'



export default {
  name: 'CardsProjects',
  props:['URLProject','IMGProject','TitleProject','InfoProject','IdProject'],
  emits: ['EditProject'],
  components: {
    checkComponent
  },
  data(){
      return{
        EditDiv:false,

        // loading Function
        LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,
      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
    EditProject(){
      const Data = {
        Id:this.IdProject,
        Url:this.URLProject,
        Img:this.IMGProject,
        Title:this.TitleProject,
        Info:this.InfoProject
      }
      this.$emit('EditProject' , Data)
    },
    async DeletePoject(){
       // Start Loading...
       this.LoadingDone = false
        this.LoadingAllow = true

        const RequestData = {
          Collection:{
            Name:'Projects'
          },
          Id:this.IdProject,
        };
        
        await axios.post(`/.netlify/functions/DeleteProject`, RequestData).then(response => {
          console.log(response);
          this.SuccessPost('הפרוייקט נמחק')
        }).catch(error => {
            console.log(error);
            this.ErrorPost('מחיקת הפרוייקט נכשלה')
        }); 
    },
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
          window.location.reload()
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },
  }
  
}
</script>

<style scoped>

.CardContainer{
  position: relative;
  border: 1px solid none;
  background-color: white;
  width: 200px;
  height: 150px;
  margin:2% 0% 2% 2%; /*for three cards */
  border-radius: 25px 25px 25px 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

img{
  height: 100%;
  width: 100%;
  border-radius: 25px 25px 25px 25px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.EditDiv{
  border: 1px solid none;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 25px 25px 25px 25px;
}

</style>




