<template>
<div class="Allcontainer">
  <div class="container">
    <h2>כניסה לאתר: {{ this.SiteName }}</h2>
    <form @submit.prevent="this.submitForm()">
      <div>
        <label for="username">שם משתמש</label>
        <input type="mail" id="username" v-model="this.UsernameProp" required/>
      </div>
      <div>
        <label for="password">סיסמה</label>
        <input type="password" id="password" v-model="this.PasswordProp" required/>
      </div>
      <button class="btn btn-primary SubmitClass" type="submit">כנס למערכת</button>
    </form>
  </div>
</div>
<checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
:DataPost="this.DataPost" :successProp="this.successProp" /> 
</template>
<script>

import axios from "axios";
import checkComponent from '@/components/checkComponent.vue'


export default {
  name: 'login',
  components: {
    checkComponent
  },
  data(){
      return{
        SiteName:'',
        UsernameProp:'',
        PasswordProp:'',

         // loading Function
         LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,

      }  
  },
  created(){
    
  },
  mounted(){
    this.SiteName = this.$route.params.RouteClient
  },
  methods: {
    async submitForm(){
      // Start Loading...
      this.LoadingDone = false
      this.LoadingAllow = true

      const RequestData =  {
        WebsiteName:this.SiteName,
        Username: this.UsernameProp,
        Password: this.PasswordProp
      }

      await axios.post('/.netlify/functions/login', RequestData).then(res => {
        console.log(res);
        localStorage.setItem("token", res.data.token);
        this.SuccessPost('החיבור בוצעה')
      }).catch(error => {
          console.log(error);
          this.ErrorPost('החיבור נכשל')
      }); ;
    },
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
        if(this.SiteName == 'administrator'){
          this.$router.push(`/administrator`);
        }else{
          this.$router.push(`/site/${this.SiteName}`);
        }
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },

  }  
}

</script>
<style scoped>

.Allcontainer{
  width: 100%;
  height: 100svh;
  background: url('../assets/photo/background.jpg') no-repeat center center/cover;
}

.container {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 500px;
  margin: auto;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(350%) blur(5px);
  animation: Showing 2s ;
}

.container h2{
  padding: 0px 0px 20px 0px;
}


.container div {
  margin-bottom: 1em;
}

.container label {
  margin-bottom: .5em;
  color: #333333;
  display: block;
}

.container input {
  border: 1px solid #CCCCCC;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}

button.SubmitClass{
  display: flex;
  margin: 30px auto 0px auto;
}


@keyframes Showing {
  from{
    opacity:0
  }
  to{
    opacity: 1;
  }
}

@media (max-width:800px){
  .container{
    position: relative;
    right: 0;
    width: 80% !important;
  }
}
</style>
