<template>

  <div class="AllContainerContact">
    <div class="ProjectContainer">
      <h2 >עריכת אודות</h2>
      <div class="BoxContainer">
          <div class="InfoDiv">
            <div class="TextDiv">
              <label for="">תפקיד  :</label>
              <input type="text" style="direction: ltr;" :value="this.JobTitle" @input="JobTitleUpdate = $event.target.value">
            </div>
            <div class="TextDiv">
              <label for="">עיר :</label>
              <input type="url" :value="this.City" @input="this.CityUpdate = $event.target.value">
            </div>
            <div class="TextDiv">
              <label for="">גיל :</label>
              <input type="url" :value="this.Age" @input="this.AgeUpdate = $event.target.value">
            </div>
            <div class="TextDiv">
              <label for="">תעסוקה :</label>
              <input type="url" :value="this.WorkSpace" @input="this.WorkSpaceUpdate = $event.target.value">
            </div>
            <div class="TextDiv">
              <label for="">תיאור:</label>
              <textarea name="" id="" cols="20" rows="7" :value="this.Info" @input="this.InfoUpdate = $event.target.value"></textarea>
            </div>
            
            
          </div>
          <div class="ImgDiv">
            <img :src="this.ImgUpdate" @mouseover="this.UploadMode=true" @mouseleave="this.UploadMode=false">
            <div class="TriggerUpload" v-if="this.UploadMode">
              <button class="btn btn-primary ChangeButton" @click="this.openWigit"><i class="bi bi-upload"></i></button>
              
            </div>
            <div class="ButtonsDiv">
                <button class="btn btn-success BtnClass" @click="this.PostProject()"><i class="bi bi-check2"></i></button>
                <button class="btn btn-danger BtnClass" @click="this.EndEdit()"><i class="bi bi-x"></i></button>

              </div>
          </div>
          

        
      </div>
    </div>
    
  </div>

  <checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
  :DataPost="this.DataPost" :successProp="this.successProp" /> 
</template>

<script>
import checkComponent from '@/components/checkComponent.vue'

import axios from 'axios'

export default {
  name: 'ContactEdit',
  props:['ContactData'],
  components: {
    checkComponent
  },
  data(){
      return{
        
        Img:'',
        Name:'',
        JobTitle:'',
        City:'',
        Age:'',
        WorkSpace:'',
        Info:'',
        Skills:[],

        ImgUpdate:'',
        NameUpdate:'',
        JobTitleUpdate:'',
        CityUpdate:'',
        AgeUpdate:'',
        WorkSpaceUpdate:'',
        InfoUpdate:'',
        SkillsUpdate:[],

         // loading Function
         LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,

        UploadMode:false,
        IdData:null,

      }  
  },
  created(){
    
  },
  mounted(){
    this.SetData()
  },
  methods: {
    async SetData(){

        this.Img = this.ContactData.ImgUrl
        this.Name = this.ContactData.Name
        this.JobTitle = this.ContactData.JobTitle
        this.City = this.ContactData.City
        this.Age = this.ContactData.Age
        this.WorkSpace = this.ContactData.WorkSpace
        this.Info = this.ContactData.Info
        this.Skills = this.ContactData.Skills

        this.ImgUpdate = this.ContactData.ImgUrl
        this.NameUpdate = this.ContactData.Name
        this.JobTitleUpdate = this.ContactData.JobTitle
        this.CityUpdate = this.ContactData.City
        this.AgeUpdate = this.ContactData.Age
        this.WorkSpaceUpdate = this.ContactData.WorkSpace
        this.InfoUpdate = this.ContactData.Info
        this.SkillsUpdate = this.ContactData.Skills

        this.IdData = this.ContactData._id
      
    },
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
          window.location.reload()
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },
    async PostProject(){
        

        var ResultLength = await this.CheckLength()
        var ResultUpdate = await this.CheckUpdate()

          if(ResultLength == true && ResultUpdate == true){
            // Start Loading...
            this.LoadingDone = false
            this.LoadingAllow = true

            const RequestData = {
              Data: { 
                ImgUrl:this.ImgUpdate,
                Name:this.NameUpdate,
                JobTitle:this.JobTitleUpdate,
                City:this.CityUpdate,
                Age:this.AgeUpdate,
                WorkSpace:this.WorkSpaceUpdate,
                Info:this.InfoUpdate
              },
              Collection:{
                Name:'Contact'
              },
              Id:this.IdData,
            };
            
            await axios.post(`/.netlify/functions/UpdateProject`, RequestData).then(response => {
              console.log(response);
              this.SuccessPost('העריכה בוצעה')
            }).catch(error => {
                console.log(error);
                this.ErrorPost('העריכה נכשלה')
            }); 

          }else if(ResultLength == false){
            alert('אנא מלא את כל הפרטים!')
          }else if(ResultUpdate == false){
            alert('שום דבר לא השתנה!')
          }
    },
    CheckLength(){
      if(this.InfoUpdate.length != 0 && this.WorkSpaceUpdate.length != 0 && this.AgeUpdate.length != 0 && this.CityUpdate.length != 0  && this.JobTitleUpdate.length != 0 && this.NameUpdate.length != 0 && this.ImgUpdate.length != 0 ){
        return true
      }else{
        return false
      }
    },
    CheckUpdate(){
      if(this.InfoUpdate != this.Info || this.WorkSpaceUpdate != this.WorkSpace || this.AgeUpdate != this.Age || this.CityUpdate != this.City  || this.JobTitleUpdate != this.JobTitle || this.NameUpdate != this.Name || this.ImgUpdate != this.Img ){
        return true
      }else{
        return false
      }
    },
    openWigit(){

      const widget = window.cloudinary.createUploadWidget(
        {cloud_name:"dfnusjlhc", upload_preset: "TechLinx"},
        (error,result)=>{
          if(!error && result && result.event == "success"){
            console.log("Done uploading ....",result.info.url)
            this.ImgUpdate = result.info.url      
          }
        }
      )
      widget.open()
    }
  }  

}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.AllContainerContact{
  border: 3px solid none;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  direction: ltr;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.ProjectContainer{ 
  border:1px solid none;
  direction: rtl;
  position: relative;
  display: flex;
  justify-content: center;
  width: 85%;
  height: 35vw;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ProjectContainer h2{
  position: absolute;
  top: 5%;
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 2vw;
}

.BoxContainer{
  border: 1px solid none;
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 5% 0px 0px 0px;
  justify-content: space-evenly;
  bottom: 5%;
  width: 90%;
  height: 80%;
  overflow: auto;
  box-shadow: rgb(171, 186, 199) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.699) -3px -3px 6px 1px inset;
}

.CardDiv{
  direction: ltr;
  display: flex;
  flex-wrap: wrap;
}


.TriggerUpload{
  border: 1px solid none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 16.5vw;
  backdrop-filter: blur(10px);
  border-radius: 15px;

}

.InfoDiv{
  border:1px solid none;
  position: relative;
  justify-content: center;
  width: 45%;
  height: fit-content;
  text-align: center;
  padding: 0px 0px 10px 0px;
  border-radius: 15px;
  /* background: #c0e3ff;   */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.TextDiv{
  display: flex;
  justify-content: space-between
}

.TextDiv label{
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1.3vw;
}

.TextDiv input,.TextDiv textarea{
  width: 60%;
  margin: 5px 0px 5px 0px;
  font-size: .8em;

}

.TextDiv textarea{
  height: 11vw;
}

.ImgDiv{
  position: relative;
  width: 40%;
  height: fit-content;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.ImgDiv img{
  /* border: 1px solid #6385a1;  */
  width: 100%;
  height: 16.5vw;
  border-radius: 15px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}



.ChangeButton{
  /* position: absolute;
  top: 0;
  right: 0; */
  padding: 5px 10px 5px 10px;
  font-size: 1vw;
}

.ChangeButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-size: 22px;
  font-weight: bold;
}

.SubmitButton{
  padding: 5px 10px 5px 10px;
  margin: 10% 0% 0% 0%;
  font-size: 1vw;
  
}

.SubmitButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-weight: bold;
}

.ButtonsDiv{
  border: 1px solid none;
  padding: 30px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}



@media (max-width: 750px) {
  .TextDiv label{
    color: #101F38;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1.2em;
  }
  .ProjectContainer{ 
    width: 90%;
    height: 80%;
    text-align: center;
    border-radius: 15px;
    background: #c0e3ff;  
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .ProjectContainer h2{
    font-size: 1.5em;
  }
  .BoxContainer{
    flex-direction: column;
    justify-content: start;
    align-items: center;
    /* height: 100%; */
    overflow: auto;
  }
  .ImgDiv{
    border: 1px solid none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
  .ImgDiv img{
    /* border: 1px solid #6385a1;  */
    width: 100%;
    height: 15vh;
    object-fit: contain;
  }

  .TriggerUpload{
    border: 1px solid none;
    height: 15vh;
  }
  .InfoDiv{
    width: 80%;

  }
  .SubmitButton{
    font-size: .7em;
  }
  .TextDiv input,.TextDiv textarea{
    width: 50%;
    margin: 5px 0px 5px 0px;
    font-size: .6em;
  }

  .TextDiv textarea{
    height: 150px;
  }
  
}


</style>




