<template>
  <div class="navbarContainer">
    <div class="LogoImg">
      <img src="@/assets/Logo/logo2.png" alt="">
    </div>
    <ul>
      <li><button class="btn CssButton" @click="this.scrollToElement('Home')">בית</button></li>
      <li><button class="btn CssButton" @click="this.scrollToElement('PortFolio')">עבודות</button></li>
      <li><button class="btn CssButton" @click="this.scrollToElement('Contact')">אודות</button></li>
    </ul>
  </div>
  <div class="threebarnav">
    <div class="brand-title HeaderLogo"><img src="@/assets/Logo/logo2.png" alt="" width="300px"></div>
    <a @click="OpenMenu" class="toggle-button">
      <span class="bar" id="first"></span>
      <span class="bar" id="two"></span>
      <span class="bar" id="three"></span>
    </a>
    <div class="navbar-links">
      <!-- <div class="brand-title"><img src="@/assets/Logo/logo2.png" alt="" width="300px"></div> -->
        <ul>
        <li><button class="btn CssButton" @click="this.scrollToElement('Home')">בית</button></li>
        <li><button class="btn CssButton" @click="this.scrollToElement('PortFolio')">עבודות</button></li>
        <li><button class="btn CssButton" @click="this.scrollToElement('Contact')">אודות</button></li>
        </ul>
        <div class="BlurDiv"></div>
    </div>
    
  </div>

</template>

<script>

import axios from 'axios'


export default {
  name: 'Navbar',
  components: {
 
  },
  data(){
      return{
        HeightBlur:null,
        HeightBlurMinus:null
      }  
  },
  created(){
    
  },
  mounted(){
    this.HeightBlur = `${window.innerHeight - 350}px`
    this.HeightBlurMinus = `-${window.innerHeight - 350}px`
  },
  methods: {
    OpenMenu(event) {
    const toggleButton = document.getElementsByClassName('toggle-button')[0]
    const navbarLinks = document.getElementsByClassName('navbar-links')[0]

      navbarLinks.classList.toggle('active')
      toggleButton.classList.toggle('rotate')

    },
    scrollToElement(name) {
      // Access the div element using the ref
      this.$emit('Scroll-ref',name)
    }
  
  }
  
}
</script>

<style scoped>


/* ----------- navbar Width Screen ------------ */

.navbarContainer{
  border: 1px solid none;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 150px;
  color: white;
  direction: rtl;
  z-index: 100;
}

.navbarContainer ul{
  position: absolute;
  display: flex;
  border: 1px solid none;
  padding: 0;
  left: 3%;
  top: 15%;
}

.navbarContainer ul li{
  border: 1px solid none;
  list-style-type: none;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  width: fit-content;
  padding: 0% 40px 0% 40px;
}
.navbarContainer ul li a{
  color: white;
  text-decoration: none;
  
}
.navbarContainer ul li:hover{
  border-top: 2px solid rgb(15, 89, 194);
  font-weight: 500;
}
.LogoImg{
  border: 1px solid none;
  position: relative;
  width: 200px;
  height: 100%;
  margin-right: 20px;
}
.LogoImg img{
  width: 100%;
}

/* responsive navbar  */

.threebarnav{
  display: none;
}

.threebarnav .navbar-links{
  display: none;
}


.threebarnav .brand-title{
  display: none ;
  width: 150px;
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2001;
  /* border: 1px solid red; */
}


.brand-title img{
  width: 100%
}

.toggle-button{
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
  z-index: 2001;
}

.toggle-button .bar{
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  transition:  0.2s ease-out;
}

button.CssButton{
  color: white;
  font-size: 1em;
  border: none;
  outline: none;
}


@media (max-width: 750px) {

 .threebarnav{
  display: block ;
} 

.threebarnav .brand-title{
  display: block ;
} 

.toggle-button {
  display: flex;
  /* border: 1px solid transparent; */
  transition:  0.2s ease-out;
}


.toggle-button.rotate #first{
  background-color: transparent;
}

.toggle-button.rotate #two{
  transform: rotate(45deg);
  position: relative;
  top: 4.5px;
  transition:  0.2s ease-in;

  
}

.toggle-button.rotate #three{
  transform: rotate(-45deg);
  position: relative;
  top: -4.5px;
  transition:  0.2s ease-in;

}

.imgAll{
  display: block;
  z-index: 2312321321321;
}

.navbar-links {
  background: linear-gradient(to right, rgb(12, 30, 79), rgb(10, 11, 19));
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  height: 350px;
  display: none;
  width: 100%;
  box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
  border-bottom: none;
  border: 1px solid none;
  z-index: 2000;
}

.BlurDiv{
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  bottom:v-bind(HeightBlurMinus);
  display: block;
  border: 1px solid none;
  height: v-bind(HeightBlur);
  width: 100%;
  z-index: 100000;
}

.navbarContainer {
  display: none;
}

.navbar-links ul{
  margin: 0;
  padding: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  flex-direction: column;
}

.navbar-links li{
  text-align: center;
  list-style: none;
  color: black;
  border: 1px solid none;
  padding: 0px 10px 0px 10px;
}

.navbar-links li:hover{
  background-color: rgb(24, 114, 193);
  color: white;
  transition: 0.2s ease-in;
}

.navbar-links li a{
  padding: .7rem 1rem;
  color: white;
  display: block;
  font-size: 1.3rem;
  text-decoration: none;
  border: 1px solid none;
}

.navbar-links.active{
  display: flex;
}

}
</style>




