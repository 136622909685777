<template>
  <div class="AllContainer">
    <div class="ImgDiv"><img :src="this.ContactData.ImgUrl" alt=""></div>
    <div class="InfoDiv">
      <div class="ImgDivResponsive"><Portrait :ImgUrl="this.ContactData.ImgUrl"/></div>

      <h2>קצת עליי</h2>
      <p>
      {{this.ContactData.Name}}
      <br>
      {{this.ContactData.JobTitle}}
      </p>
      <hr>
      <div class="BottonInfo">
        <span class="SmallText"><b class="HeaderText">עיר: </b>{{this.ContactData.City}}</span>
        <br>
        <span class="SmallText"><b class="HeaderText">גיל: </b>{{this.ContactData.Age}}</span>
        <br>
        <span class="SmallText"><b class="HeaderText">תעסוקה: </b>{{this.ContactData.WorkSpace}}</span>
      <br>
      <p class="ContactP">
        {{this.ContactData.Info}}
      </p>   
      
      <div class="SkillsContainer">
        <div class="Skill" v-for="Skill in this.ContactData.Skills" :key="Skill">{{ Skill }}</div>
      </div>
      <div class="CvDiv">
        <CvButton/>

      </div>
    
    </div>
         
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import CvButton from '@/components/CvButton.vue'
import Portrait from '@/components/Portrait.vue'



export default {
  name: 'AboutComponent',
  props:['ContactData'],
  components: {
    CvButton,
    Portrait
  },
  data(){
    return{
      // Skills:[
      //   'HTML','CSS','JavaScript','Vue.js','Node.js','Express.js','MongoDB','Python','Java','PhotoShop','SharePoint',
      // ]

    }  
  },
  created(){
    
  },
  mounted(){
    console.log()
  },
  methods: {
    
  }
}
</script>

<style scoped>

.AllContainer{
  border: 1px solid none;
  direction: rtl;
  display: flex;
  width: 70%;
  height: 70%;
  animation: fade-in linear;
  animation-timeline: view();
  animation-range: entry-crossing;
}

@keyframes fade-in{
  from{scale: .8; opacity:0}
  to{scale: 1; opacity:1}
}

.ImgDivResponsive{
  display: none;
}

.AllContainer .ImgDiv{
  border: 1px solid none;
  width: 40%;
  padding: 7px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.AllContainer .ImgDiv img{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}
.AllContainer .InfoDiv{
  border: 1px solid none;
  width: 60%;
  padding: 5px 25px 0px 0px;
}

.AllContainer .InfoDiv h2,.AllContainer .InfoDiv h6{
  font-weight: bold;
}

.AllContainer .InfoDiv p{
  font-weight: 600;
}
.AllContainer .InfoDiv hr{
  width: 40%;
}

.HeaderText{
  font-size: 17px;
  font-weight: none;
}

.ContactP{
  border: 1px solid none;
  margin-top: 10px;
}

.SkillsContainer{
  border: 1px solid none;
  direction: ltr;
  display: flex;
  flex-wrap: wrap;
  height: 16svh;
  width: 100%;
  overflow-y: auto;
}

.Skill{
 /* border: 1px solid purple; */
  /* height: 40px; */
  width: fit-content;
  height: fit-content;
  padding: 10px 20px 10px 20px;
  margin: 10px 6px 0px 6px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.CvDiv{
  border: 1px solid none;
  width: fit-content;
  margin-top: 20px;
}

@keyframes showingImg {
  0% 
  {
    height: 0px;

  }
  100% 
  {
    height: 310px;

  }
}

@media (max-width: 750px) {

  .AllContainer{
    border: 1px solid none;
    width: 90%;
    height: 90%;
    padding: 0px 0px 20px 0px;
  }
  .AllContainer .ImgDiv{
  
   display: none;

}
.AllContainer .InfoDiv{
    width: 100%;
  }

.AllContainer .ImgDivResponsive{
  
  border: 1px solid none;
  position: relative;
  display: flex;
  animation: showingMedia 0.6s ease-in;
  right: 50%;
  transform: translateX(50%);
  width: 200px;
  height: 270px;
  animation: showingImg 4s ease-in;
  padding: 0px 0px 10% 0px;

}

  .SkillsContainer{
    border: 1px solid none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    /* overflow-y: scroll; */
    padding: 5px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  .CvDiv{
    margin:20px auto 0px auto;
  }
}

</style>




