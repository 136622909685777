<template>

    <div @mouseover="this.addFillDocument()" @mouseleave="this.removeFillDocument()" class="OptionResumeHover">
    <div class="FirstText" v-show="!this.isResumeHover"><i class="bi bi-download"></i> קו"ח</div>

    <div v-show="this.isResumeHover" class="AllButton">
        <div class="OptionsDiv borders"> 
            <a class="resumeBtn" :href="getFileUrl('Hebrew')" download="קורות חיים - דוד דבח.pdf"><button class="btn ">עברית</button></a>
            </div>
            <div class="OptionsDiv">
            <a class="resumeBtn" :href="getFileUrl('English')" download="David Dabah - CV.pdf"><button class="btn">English</button></a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CvButton',
    props:[],
    components: {
    
    },
    data(){
        return{
        isResumeHover:false
        }  
    },
    created(){
    
    },
    mounted(){
    },
    methods: {
    getFileUrl(language) {
        if(language == 'English'){
        return process.env.BASE_URL + 'files/' + 'CV - David Dabah.pdf';
        }else if(language == 'Hebrew'){
        return process.env.BASE_URL + 'files/' + 'קורות חיים - דוד דבח.pdf';
        }
    },
    addFillDocument(){
        const docu = document.getElementsByTagName('i')[0]

        docu.classList.replace('bi-file-earmark-text','bi-file-earmark-text-fill')
        this.isResumeHover=true
    },
    removeFillDocument(){
        const docu = document.getElementsByTagName('i')[0]

        docu.classList.replace('bi-file-earmark-text-fill','bi-file-earmark-text')
        this.isResumeHover=false
    }
    
    }
    
}
</script>

<style scoped>

.OptionResumeHover{
    border-radius: 25px;
    width: 150px;
    height: 50px;
    cursor: pointer;
    color: white;
    text-decoration: none;
    text-align: center;
    border: 1px solid none;
    background-color: blue;
    transition: ease 0.2s;
    overflow: hidden;
}

.FirstText{
    border:1px solid none;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 45px;
    font-size: 20px;
}


.AllButton{
    border: 1px solid none;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2px;
}

.AllButton button{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.AllButton button:hover{
    background-color: rgb(3, 3, 214);
    color: white;
}


.OptionsDiv a{
    color: black;
    outline: none;
    border: none;
}

.borders{
    border-left: 1px solid white;
}


</style>
    
    
    
    
    