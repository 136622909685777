<template>
  <div class="carousel">
    <button @click="next" class="nav-button"><i class="bi bi-arrow-right"></i></button>
    <div class="carousel-container">
      <div
        class="carousel-items"
        :style="{ transform: `translateX(-${this.currentIndex * this.WidthForTranslate}%)` }">
          <CardContainer v-for="(Portfolio, index) in this.cards" :key="index"
          :URLProject="Portfolio.Url" :IMGProject="Portfolio.Img"
          :TitleProject="Portfolio.Title" :InfoProject="Portfolio.Info"/>
      </div>
    </div>
    <button @click="prev" class="nav-button"><i class="bi bi-arrow-left"></i></button>
    <div class="dots">
      <span
        v-for="(dot, index) in numDots"
        :key="index"
        :class="{ active: this.currentIndex === index }"
        class="dot"
        @click="setIndex(index)"
      ></span>
    </div>
  </div>

</template>

<script>

import CardContainer from '@/components/CardContainer.vue'

export default {
  name: 'carousel',
  props:['cards'],
  components: {
    CardContainer
  },
  data(){
      return{
        currentIndex: 0,
        WidthForTranslate:null,
        WidthWeb:null,
        VisibleCards:null,
      }  
  },
  beforeMount(){
    this.WidthFunction()
   
  },
  beforeUpdate(){
    if(this.cards.length <= 2 && this.WidthWeb > 1000){
      console.log(this.Count)
      document.querySelector('.carousel-items').style.justifyContent='center'
    }
  },
  computed: {
    numDots() {
      if(Math.ceil(this.cards.length / this.VisibleCards) <= 1){
        return Math.ceil(this.cards.length+1 / this.VisibleCards); // dynamic number of visible cards
      }else{
        return Math.ceil(this.cards.length - this.VisibleCards) + 1; // dynamic number of visible cards
      }
    },
    Count(){
      return this.cards.length
    }
  },
  mounted(){
    window.addEventListener("resize", this.WidthFunction);
    
  },
  methods: {
    next() {
      if(this.currentIndex == this.numDots - 1){
        this.currentIndex = 0
      }else if (this.currentIndex < this.numDots - 1) {
          this.currentIndex++;
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    setIndex(index) {
      this.currentIndex = index;
    },
    WidthFunction(){
      this.WidthWeb = window.innerWidth
      if(this.WidthWeb <= 1000){
        this.WidthForTranslate = 101
        this.VisibleCards = 1
      }else{
        this.WidthForTranslate = 33.333
        this.VisibleCards = 3
        if (this.currentIndex >= this.numDots) {
          this.currentIndex = this.numDots -1
        }
      }
    }
  }
  
}
</script>

<style scoped>

.carousel {
  border: 1px solid none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 6%;
  width: 100%;
  height: 32vw;
}

.nav-button {
  background-color: #101F38;
  color: white;
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  
}

.carousel-container {
  border: 1px solid none;
  overflow: hidden;
  width: 85%; /*for three items */
  height: 100%;
  position: relative;
  animation: fade-in linear;
  animation-timeline: view();
  animation-range: entry-crossing;
}

@keyframes fade-in{
  from{scale: .8; opacity:0}
  to{scale: 1; opacity:1}
}

.carousel-items {
  border: 1px solid none;
  display: flex;
  direction: ltr;
  height: 35vw;
  /* transition:  .3s ease; */
  transition: ease 200ms;
}


.dots {
  position: absolute;
  bottom: -30px;
  border: 1px solid none;
  display: flex;
  direction: ltr;
  justify-content: center;
  z-index: 10;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #101F38;
}

@media (max-width: 1000px) {
  .carousel {
    height: 500px;
  }
  .carousel-container {
    width: 60%; /*for one item */
  }
  .carousel-items {
    height: 100%;
  }
  .nav-button{
    margin: 15px;
  }
}

@media (max-width: 700px) {
  .carousel {
    height: 500px;
  }
  .carousel-container {
    width: 70%; /*for one item */
  }
  .carousel-items {
    height: 100%;
  }
  
}

</style>




