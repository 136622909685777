<template>
  <div class="AllContainerAdd">
    <div class="InfoDiv">
      <div class="TextDiv">
        <label for="">שם פרוייקט:</label>
        <input type="text" v-model="this.TitlePost">
      </div>
      <div class="TextDiv">
        <label for="">כתובת Url:</label>
        <input style="direction: ltr;" type="url" v-model="this.UrlPost">
      </div>
      <div class="TextDiv">
        <label for="">תיאור:</label>
        <textarea name="" id="" cols="20" rows="7" v-model="this.InfoPost"></textarea>
      </div>
    </div>
    <div class="ImgDiv">
      <img :src="this.ImgPost" alt="">
      <button class="btn ChangeButton" @click="this.openWigit"><i class="bi bi-upload"></i></button>
      <button class="btn btn-primary SubmitButton" @click="this.PostProject()"><i class="bi bi-upload"></i>העלה פרוייקט</button>
    </div>
  </div>
  

  <checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
    :DataPost="this.DataPost" :successProp="this.successProp" /> 

</template>

<script>

import axios from 'axios'
import checkComponent from '@/components/checkComponent.vue'



export default {
  name: 'AddProject',
  props:[],
  components: {
    checkComponent
  },
  data(){
      return{
        
        TitlePost:'',
        ImgPost:'https://cdni.iconscout.com/illustration/premium/thumb/social-media-image-upload-4358258-3618854.png',
        UrlPost:'',
        InfoPost:'',

        CheckImg:'https://cdni.iconscout.com/illustration/premium/thumb/social-media-image-upload-4358258-3618854.png',

        // loading Function
        LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,

      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {

      async PostProject(){
        var Result = await this.CheckLength()

          if(Result == true){
             // Start Loading...
             this.LoadingDone = false
            this.LoadingAllow = true

            const RequestData = {
              Data: { 
                Img:this.ImgPost,
                Url:this.UrlPost,
                Title:this.TitlePost,
                Info:this.InfoPost, 
              },
              Collection :{
                Name:'Projects'
              }
            };

            await axios.post(`/.netlify/functions/PostProjects`, RequestData).then(response => {
              console.log(response.data);
              this.SuccessPost('הפרוייקט התווסף')              
            }).catch(error => {
                console.log(error);
                this.ErrorPost('הוספת הפרוייקט נכשלה')

            }); 

          }else if(Result == 'imgfalse'){
            alert('אנא הוסף תמונה מתאימה לפרוייקט')
          }else {
            alert('אנא מלא את כל הפרטים!')
          }
        
    },
    CheckLength(){
      if(this.ImgPost.length != 0 && this.UrlPost.length != 0 && this.TitlePost.length != 0 && this.InfoPost.length != 0 ){
        if(this.ImgPost == this.CheckImg){
          return 'imgfalse'
        }
        return true
      }else{
        return false
      }
    },
    openWigit(){

      const widget = window.cloudinary.createUploadWidget(
        {cloud_name:"dfnusjlhc", upload_preset: "TechLinx"},
        (error,result)=>{
          if(!error && result && result.event == "success"){
            console.log("Done uploading ....",result.info.url)
            this.ImgPost = result.info.url      
          }
        }
      )
      widget.open()
    },
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
          window.location.reload()
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },
  }
  
}
</script>

<style scoped>

/* add Sections */

.AllContainerAdd{
  border: 1px solid none;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 20px 0px 0px 0px;
  justify-content: space-evenly;
}


.InfoDiv{
  border:1px solid none;
  position: relative;
  justify-content: center;
  width: 45%;
  height: fit-content;
  text-align: center;
  padding: 0px 0px 10px 0px;
  border-radius: 15px;
  /* background: #c0e3ff;   */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.TextDiv{
  display: flex;
  justify-content: space-between
}

.TextDiv label{
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1.3vw;
}

.TextDiv input,.TextDiv textarea{
  width: 60%;
  margin: 5px 0px 5px 0px;
  font-size: .8em;

}

.TextDiv textarea{
  height: 11vw;
}

.ImgDiv{
  border:1px solid none;
  position: relative;
  width: 40%;
  height: fit-content;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.ImgDiv img{
  /* border: 1px solid #6385a1;  */
  width: 100%;
  height: 16.5vw;
  border-radius: 15px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.ChangeButton{
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px 5px 10px;
  font-size: 1vw;
}

.ChangeButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-size: 22px;
  font-weight: bold;
}

.SubmitButton{
  padding: 5px 10px 5px 10px;
  margin: 10% 0% 0% 0%;
  font-size: 1vw;
  
}

.SubmitButton i{
  padding: 0px 10px 0px 5px;
  float: left;
  font-weight: bold;
}

@media (max-width: 750px) {
  .TextDiv label{
    color: #101F38;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1.2em;
  }
  .AllContainerAdd{
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    overflow: auto;
  }
  .ImgDiv{
    border: 1px solid none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
  .ImgDiv img{
    /* border: 1px solid #6385a1;  */
    width: 100%;
    height: 15vh;
    object-fit: contain;

  }
  .InfoDiv{
    width: 80%;

  }
  .SubmitButton{
    font-size: .7em;
  }
  .TextDiv input,.TextDiv textarea{
    width: 50%;
    margin: 5px 0px 5px 0px;
    font-size: .6em;
  }

  .TextDiv textarea{
    height: 150px;
  }
  
}


</style>




