<template>
  <div class="Social-Media-Div">
    <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-instagram icons-footer insta"></i></a>
    <a href="https://www.facebook.com/david.benzohar"><i class="bi bi-facebook icons-footer"></i></a>
    <a href="https://wa.link/oaqegs"><i class="bi bi-whatsapp icons-footer"></i></a>
    <a href="https://il.linkedin.com/in/david-dabah-670a79237?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.co"><i class="bi bi-linkedin icons-footer"></i></a>
    <!-- <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-twitter icons-footer"></i></a>
    <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-snapchat icons-footer"></i></a> -->

  </div>
</template>

<script>

export default {
  name: 'SocialMedia',
  props:[],
  components: {
  },
  data(){
      return{
      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
  
  }
  
}
</script>

<style scoped>
/* -------------------- Social-Media-Div ------------------- */

.Social-Media-Div{
  position: absolute;
  bottom: 5%;
  left: 2%;
  width: fit-content;
  display: flex;
  border: 1px solid none;
  animation: showingSocial 0.6s ease-in;
}

.icons-footer{
  transition: 0.2s ease-in-out;
  color: whitesmoke;
  font-size: 30px;
  margin-left: 30px;
}

.insta:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bi-facebook:hover{
  color: #4267B2;
}

.bi-linkedin:hover{
  color: #4267B2;
}

.bi-whatsapp:hover{
  color: rgb(68, 188, 68);
}

.bi-twitter:hover{
  color:rgb(0, 191, 255);
}
.bi-snapchat:hover{
  color: yellow;
}

/* ------------ keyframes ----------- */

@keyframes showingSocial {
  0% 
  {
    opacity:0;
  }
  100% 
  {
    opacity: 1;
  }
}

@media (max-width: 750px) {
  .Social-Media-Div{
    border: 1px solid none;
    left: 50%;
    transform: translate(-50%);
    justify-content: space-between;
    width: 70%;
    bottom: 3%;
  }
  .icons-footer{
    margin: 0;
  }
}

</style>




