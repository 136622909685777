<template>
  <div class="AllContainer">
    <div class="ProjectContainer">
      <h2 v-if="this.AllProject">פרוייקטים</h2>
      <h2 v-else-if="this.AddMode">הוספת פרוייקט</h2>
      <h2 v-else>עריכת פרוייקט</h2>
      <div class="OptionsBar">
        <span class="OptionsButton" @click="this.OptionsMode('All')"><i class="bi bi-grid" style="color: black;"></i></span>
        <span class="OptionsButton" @click="this.OptionsMode('Add')"><i class="bi bi-plus" style="color: black;"></i></span>
      </div>
      <div class="BoxContainer">
        <div class="CardDiv">
          <CardsProjects  v-if="this.AllProject" v-for="(Portfolio, index) in this.Projects" :key="index"
            :URLProject="Portfolio.Url" :IMGProject="Portfolio.Img"
            :TitleProject="Portfolio.Title" :InfoProject="Portfolio.Info"
            @EditProject="this.EditOption" :IdProject="Portfolio._id"/>
        </div>
        <div class="AddProject" v-if="this.AddMode">
          <AddProject/>
        </div>
        <div class="EditProject" v-if="this.EditMode">
          <EditProject :EditProp="this.EditProp" @endEdit="this.OptionsMode('All')"/>
        </div>
        
      </div>
  </div>
  </div>


</template>

<script>

import axios from 'axios'
import CardsProjects from '@/components/AdminComp/CardsProjects'
import AddProject from '@/components/AdminComp/AddProject'
import EditProject from '@/components/AdminComp/EditProject'


export default {
  name: 'ProjectsEdit',
  props:['Projects'],
  components: {
    CardsProjects,
    AddProject,
    EditProject
  },
  data(){
      return{
        AllProject:true,
        AddMode:false,
        EditMode:false,

        EditProp:[]


      }  
  },
  created(){
    
  },
  mounted(){

    
  },
  methods: {
    EditOption(event){
      this.EditProp = event
      this.OptionsMode('Edit')
    },
    OptionsMode(Option){
      this.ResetOptions()
      if(Option == 'All'){
        this.AllProject = true
      }else if(Option == 'Add'){
        this.AddMode = true
      }else if(Option == 'Edit'){
        this.EditMode = true
      }
    },
    ResetOptions(){
      this.AllProject = false
      this.AddMode = false
      this.EditMode = false
    }
  }  

}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.AllContainer{
  border: 3px solid none;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  direction: ltr;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.ProjectContainer{ 
  border:1px solid none;
  direction: rtl;
  position: relative;
  display: flex;
  justify-content: center;
  width: 85%;
  height: 35vw;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ProjectContainer h2{
  position: absolute;
  top: 5%;
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}



.OptionsBar{
  border: 1px solid none;
  position: absolute;
  direction: rtl;
  display: flex;
  top:0;
  right: 0;
  width: fit-content;
  height: fit-content;
  background: #86c5f8;  
  border-radius: 0px 10px 0px 0px;
}
.OptionsBar span.OptionsButton{
  border: 1px solid none;
  /* width: 50%; */
  padding: 5px 20px 5px 20px;
  border-left: 1px solid #c0e3ff; 
  font-size: 20px; 
  transition: ease 0.2s;
  cursor: pointer;
}

.OptionsBar span.OptionsButton:first-child{
  border-radius: 0px 10px 0px 0px;
}


.OptionsBar span.OptionsButton:hover{
  background-color: #b4d6f1; 
}

.BoxContainer{
  border: 1px solid none;
  position: absolute;
  bottom: 5%;
  width: 90%;
  height: 70%;
  overflow: auto;
  box-shadow: rgb(171, 186, 199) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.699) -3px -3px 6px 1px inset;
}

.CardDiv{
  direction: ltr;
  display: flex;
  flex-wrap: wrap;
}

/* --- add div --- */

.AddProject, .EditProject{
  position: relative;
  border: 1px solid none;
  width: 100%;
  height: 100%;
  padding: 10px;
  
}

@media (max-width: 750px) {
  .ProjectContainer{ 
    border:1px solid none;
    direction: rtl;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70svh;
    text-align: center;
    border-radius: 15px;
    background: #c0e3ff;  
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}





</style>




