<template>

  <div class="AllContainerContact">
    <div class="container">
    <h2>הרשאות לאתר:</h2>
    <form @submit.prevent="this.SubmitPermission()">
    <section>
      <i class="bi bi-browser-chrome Position"></i>
      <input class="InputContactUs" type="text" v-model="this.WebsiteNameProp" placeholder="שם אתר" required>
    </section>
    <section>
      <i class="bi bi-person Position"></i>
      <input class="InputContactUs" type="email" v-model="this.UsernameProp" placeholder="שם משתמש" required>
    </section>
    <section>
      <i class="bi bi-key Position"></i>
      <input class="InputContactUs" type="password" v-model="this.PasswordProp" placeholder="סיסמה" required>
    </section>
      <button class="btn btn-primary SubmitClass" type="submit">שליחה</button>
    </form>
  </div>
    
  </div>

  <checkComponent :LoadingAllow="this.LoadingAllow" :LoadingDone="this.LoadingDone"
  :DataPost="this.DataPost" :successProp="this.successProp" /> 
</template>

<script>
import checkComponent from '@/components/checkComponent.vue'

import axios from 'axios'

export default {
  name: 'PermissionEdit',
  // props:['ContactData'],
  components: {
    checkComponent
  },
  data(){
      return{
        

        // loading Function
        LoadingAllow:false,
        LoadingDone:null,
        DataPost:null,
        successProp:null,

        WebsiteNameProp:'',
        UsernameProp:'',
        PasswordProp:''

      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
    async SubmitPermission(){
      // Start Loading...
      this.LoadingDone = false
      this.LoadingAllow = true

      const RequestData =  {
        WebsiteName:this.WebsiteNameProp,
        Username: this.UsernameProp,
        Password: this.PasswordProp
      }

      await axios.post('/.netlify/functions/register', RequestData).then(response => {
              console.log(response);
              this.SuccessPost('ההרשאה בוצעה')
            }).catch(error => {
                console.error(error.response.data.message);
                this.ErrorPost('ההרשאה נכשלה')
            }); ;
    },
    SuccessPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = true
        this.successProp = prop
        setTimeout(() =>{
          window.location.reload()
        },"2000")
      },"2000")
    },
    ErrorPost(prop){
      setTimeout(() =>{
        this.LoadingDone = true
        this.DataPost = false
        this.successProp = prop
        setTimeout(() =>{
          this.LoadingDone = true
          this.DataPost = false
          this.LoadingAllow = false
        },"2000")
      },"2000")
    },
    CheckLength(){
      if(this.InfoUpdate.length != 0 && this.WorkSpaceUpdate.length != 0 && this.AgeUpdate.length != 0 && this.CityUpdate.length != 0  && this.JobTitleUpdate.length != 0 && this.NameUpdate.length != 0 && this.ImgUpdate.length != 0 ){
        return true
      }else{
        return false
      }
    },
  }  

}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.AllContainerContact{
  border: 3px solid none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width:700px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  width: 90%;
  margin: auto;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 15px;
  background: #c0e3ff;
  backdrop-filter: saturate(350%) blur(5px);
  animation: Showing 2s ;
  direction: rtl;
}

.container h2{
  padding: 0px 0px 30px 0px;
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

form{
  border:1px solid none;
  direction: rtl;
  display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


section{
  position: relative;
  display: flex;
}

input.InputContactUs{
  outline: none;
  border: none;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
  padding: 10px 35px 10px 10px;
  width: 100%;
}

i.Position{
  position: absolute;
  right: 0;
  font-size: 22px;
  padding: 7px;
  color: #101F38;
}

button.SubmitClass{
  height: fit-content;
  width: fit-content;
  padding: 10px;
  margin-right: auto;
  margin-left: auto
}




</style>




