<template>
<div class="TitleHomePage">
  <div class="ribbon ribbon-top-left"><span>Open TO Work</span></div>
  <h1>דוד דבח</h1>
  <h3>מפתח אתרים</h3>
  <p class="paragrafh">{{ this.HomeData.Info }}</p>
  <div class="CvDiv">
    <CvButton/>
  </div>
  
</div>
  <div class="ImgDiv">
    <Portrait :ImgUrl="this.HomeData.ImgUrl"/>
  </div>
  <SocialMedia/>
</template>

<script>

import CvButton from '@/components/CvButton.vue'
import SocialMedia from '@/components/SocialMedia.vue'
import Portrait from '@/components/Portrait.vue'

export default {
  name: 'HomePageComponent',
  props:['HomeData'],
  components: {
    CvButton,
    SocialMedia,
    Portrait
  },
  data(){
    return{
    }  
  },
  created(){
    
  },
  mounted(){
    
  },
  methods: {
  
  }
  
}
</script>

<style scoped>


/* -------------------  TitleHomePage -------------- */

.TitleHomePage{
  border: 1px solid none;
  position: absolute;
  display: flex;
  flex-direction: column;
  direction: rtl;
  text-align: right;
  width: 30%;
  top: 26%;
  right: 7%;
  animation: showing 0.6s ease-in;
}

.TitleHomePage h1{
  color: #f1f1f1;
  font-size: 5.3vw;
}

.TitleHomePage h3{
  color: #f1f1f1;
  font-size: 2.4vw;
}
.TitleHomePage p.paragrafh{
  width: 100%;
  text-align: right;
  white-space: pre-line;
  color: white;
}

.ImgDiv{
  border: 1px solid none;
  position: absolute;
  display: flex;
  left: 10%;
  top: 25%;
  width: 400px;
  /* height: 310px; */
  animation: showingImg 4s ease-in;
}


.CvDiv{
  position: relative;
  width: fit-content;
  top: 50px;
  right: 40%;
  transform: translate(50%);
}


/* ------------ keyframes ----------- */

@keyframes showing {
  0% 
  {
    opacity:0;
    transform: translatex(80px);
  }
  100% 
  {
    opacity: 1;
    transform: translatex(0px);
  }
}


@keyframes showingMedia {
  0% 
  {
    opacity:0;
  }
  100% 
  {
    opacity: 1;
  }
}

@keyframes showingImg {
  0% 
  {
    height: 0px;

  }
  100% 
  {
    height: 310px;

  }
}
@keyframes showingImgResponsive {
  0% 
  {
    height: 0px;

  }
  100% 
  {
    height: 155px;

  }
}

@media (max-width: 850px) {
  .ContactCss{
    padding: 20px 0px 0px 0px;
    height: auto;
  }
  .ImgDiv{
    width: 300px;
  }
}

@media (max-width: 750px) {

    .TitleHomePage{
      border: 1px solid none;
      width: 80%;
      top: 60%;
      right: 50%;
      transform: translate(50%,-50%);
      animation: showingMedia 0.6s ease-in;
      z-index: 1000;
     
    }
    .TitleHomePage h1{
      color: #f1f1f1;
      font-size: 50px;
    }

    .TitleHomePage h3{
      color: #f1f1f1;
      font-size: 20px;
    }
    .TitleHomePage h1,.TitleHomePage h3,.TitleHomePage p{
      text-align: center !important;
    }
    .ImgDiv{
      left: 50%;
      transform: translate(-50%);
      top: 17%;
      width: 200px;
      height: 155px;
      animation: showingImgResponsive 4s ease-in;

    }
    .CvDiv{
      width: fit-content;
      position: relative;
      top: 50px;
      right: 50%;
      transform: translate(50%);
    }
    .ribbon{
      display: none;
    }
   
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 16px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}


/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
</style>




