<template>
    <div>
      <!-- Render the component dynamically -->
      <component :key="dataUser" :is="currentComponent" :user="user" :Data="dataUser" v-if="render && dataUser"/>
    </div>
  </template>
  
  <script>
  import { ref, defineAsyncComponent, onMounted, watch,nextTick  } from 'vue';
  import { useRoute ,useRouter} from 'vue-router';
  import { markRaw } from 'vue';
  import undefinedPage from '@/landing-page/undefinedPage.vue';
  import axios from 'axios'
  
  export default {
    name: 'ProjectForms',
    setup() {
      const currentComponent = ref(null);
      const user = ref(null);
      const render = ref(null);
      const dataUser = ref(null);
      const route = useRoute();

  
      const loadDynamicComponent = async () => {
        const routeName = route.params.ProjectName || 'undefinedPage';
        // Use the utility function to load the component dynamically
        const AsyncComponent = defineAsyncComponent(() => 
          import(`@/landing-page/Sites/${routeName}.vue`)
            .catch(() => undefinedPage) // Return the NotFound component on error
        );
        

        currentComponent.value = markRaw(AsyncComponent)
        const token = localStorage.getItem("token"); // Replace this with the actual token

        AsyncComponent.__asyncLoader().then(res =>{
          // console.log(res.name)
          
          if(res.name == 'undefinedPage'){
            render.value = 'unAprrove'
            dataUser.value = 'undefinedPage'
          }else{
            GetData()
            if(token){
              userData(token);
            }else{
              render.value = 'Guest'
            }
            
          }
           
        })

      };
      

      const userData = async (token) => {
 
      await axios
        .get('/.netlify/functions/user-data', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            WebsiteNameProp: route.params.ProjectName,
          }
        })
        .then(response => {
          // console.log(response.data)
          user.value = response.data
          render.value = 'approve'

        })
        .catch(error => {
            console.error('Error fetching user data:', error);
            render.value = 'unapprove'

        });
      };

      const GetData = async (token) => {
      
      await axios
        .get('/.netlify/functions/GetClients', {
          params: {
            WebsiteName: route.params.ProjectName,
          }
        })
        .then(response => {
          // console.log(response.data)
          dataUser.value = JSON.stringify(response.data)
        })
        .catch(error => {
            console.error('Error fetching user data:', error);
        });
      };
  
      // Load the component when the component is mounted
      onMounted(async () => {
        await loadDynamicComponent();
      });
  
      // Watch for changes in the route parameters
      watch(() => route.params.ProjectName, (newValue) => {
        loadDynamicComponent();
        
      });
  
      return {
        currentComponent,
        user,
        render,
        dataUser
      };
    }
  };
  </script>
  