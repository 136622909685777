<template>
  <div class="CardContainer">
    <img :src="this.IMGProject">
    <div class="ContextDiv">
      <div class="Header">
        <h4>{{ this.TitleProject }}</h4>
      </div>
        <p>{{ this.InfoProject }}</p>
        <div class="ButtonDiv">
          <a :href="this.URLProject" v-if="this.URLProject != 'techlinx.co.il'" ><button class="btn btn-primary PassUrl"><i class="bi bi-web"></i>מעבר לאתר</button></a>
          <a :href="this.URLProject" v-else><button class="btn btn-primary PassUrl" disabled><i class="bi bi-web"></i>מעבר לאתר</button></a>
        </div>
        
    </div>
  </div>

</template>

<script>

import axios from 'axios'


export default {
  name: 'CardContainer',
  props:['URLProject','IMGProject','TitleProject','InfoProject'],
  components: {
 
  },
  data(){
      return{
      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
    RouteProject(url){
      window.location = url
    }
  }
  
}
</script>

<style scoped>

.CardContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid none;
  background-color: white;
  min-width: 31.333% !important; /*This ensures 3 cards are visible */
  max-width: 31.333% !important; /*This ensures 3 cards are visible */
  height: 90%;
  margin:0 1% 0 1%; /*for three cards */
  border-radius: 25px 25px 20px 20px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
@media (max-width: 1000px) {
  .CardContainer {
    margin:0 3% 0 3%; /* for one card */
    min-width: 95% !important; /* This ensures 1 card are visible */
    max-width: 95% !important; /* This ensures 1 card are visible */
    
  }
}

img{
  height: 50%;
  width: 100%;
  border-radius: 25px 25px 0px 0px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ContextDiv{
  border: 1px solid none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50%;
  width: 100%;
  padding: 12px;
  border-radius: 0px 0px 20px 20px;
}
.ContextDiv .Header{
  border: 1px solid none;
  width: 85%;
  height: 20%;
}

.ContextDiv p{
  border: 1px solid none;
  direction: rtl;
  width: 85%;
  height: 60%;
  display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
.ButtonDiv{
  position: relative;
  width: 85%;
  height: 20%;
}
.ButtonDiv button.PassUrl{
  border-radius: 30px;
}

@media (max-width: 1000px) {
  .ContextDiv p{
    height: 50%;
    -webkit-line-clamp: 4;
  }
}





</style>




