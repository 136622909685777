<template>
  <Navbar @Scroll-ref="this.ScrollDiv"/>

<div class="BoxContainer HomePageColor" ref="Home" >

  <HomePageComponent :HomeData="this.HomeData" v-if="this.HomeData != undefined"/>
</div>
<div class="BoxContainer PortfolioCss" ref="PortFolio">
  <ProjectsComponent :cards="this.Projects" v-if="this.Projects != undefined"/>
  
</div>
<div class="BoxContainer ContactCss" ref="Contact">
    <AboutComponent :ContactData="this.ContactData" />

</div>


</template>

<script>

import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import HomePageComponent from '@/view/CompHome/HomePageComponent.vue'
import ProjectsComponent from '@/view/CompHome/ProjectsComponent.vue'
import AboutComponent from '@/view/CompHome/AboutComponent.vue'


export default {
  name: 'Home',
  components: {
    Navbar,HomePageComponent,ProjectsComponent,AboutComponent
  },
  data(){
      return{
        HomeData:[],
        Projects:[],
        ContactData:[],
        
        getdata:false,
  //       Projects: [
  //       {Title:'אתר תקלות',url:'https://noyacakes.netlify.app/#/',img:'https://media.licdn.com/dms/image/D4D12AQErV5S_buj95w/article-cover_image-shrink_600_2000/0/1662363673907?e=2147483647&v=beta&t=FLqEHqQ_zKwj_8qH6Thrac6b2KhPN9yLjxHf0bOxw1A',Info:
  //         `
  // אני חייל משוחרר מגדוד התקשוב המטכל"י 383 ומגיע מתחום הרשתות , במהלך השירות פיתחתי   גדש גשד שדג דש גדשג שדג דשג שד גדשג דשג שד ג גדשג דש גד שדג דשג מגוון אפליקציות לרשת הצה"לית ששימשו לקידום והתמקצעות המחלקה.
  // בעל יכולת למידה עצמית גבוהה ומוטיבציה גדולה ללמוד, להתפתח ולהשקיע.
  //         `},

  //       ]

      }  
  },
  created(){
    this.GetData()
  },
  async mounted(){
  
    
  },
  methods: {
    async GetData(){

      const Home = axios.get('/.netlify/functions/GetDataHome');
      const Projects = axios.get('/.netlify/functions/GetDataProjects');
      const Contact = axios.get('/.netlify/functions/GetDataContact');
      // you could also use destructuring to have an array of responses
      await axios.all([Home, Projects, Contact]).then(axios.spread((Home, Projects, Contact) => {
      this.HomeData = Home.data
      this.Projects = Projects.data
      this.ContactData = Contact.data
      }));
    
    },
    ScrollDiv(name){
      console.log(name)
      var targetDiv
      if (name == 'Home') {
        targetDiv = this.$refs.Home;
      }else if (name == 'PortFolio') {
        targetDiv = this.$refs.PortFolio;
      }else if (name == 'Contact') {
        targetDiv = this.$refs.Contact;
      }
      console.log(targetDiv)
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }  
}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.BoxContainer{
  position: relative;
  width: 100%;
  height: 100svh;
}

.HomePageColor{
  background: linear-gradient(to right, rgb(12, 30, 79), rgb(10, 11, 19));
}
.PortfolioCss{
  border: 1px solid none;
  /* background: #B0C4DE; */
  /* background: #87CEEB;   */
  background: #b9dfff;  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}



.ContactCss{
  border: 1px solid none;
  background: #d5ecff;  
  /* background: #87CEEB;   */
  /* background: #b9dfff;   */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 750px) {
  .ContactCss{
    padding: 20px 0px 0px 0px;
    height: auto;
  }
}





</style>




