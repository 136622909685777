<template>
  <!-- <div class="AllContainer">
    <div class="ImgDiv">
      
    </div>

    <div class="InfoDiv">
      <h2>תיאור</h2>
      <p>
        {{ this.HomeData.Info }}
      </p>
      
    </div>

  </div> -->

  <div class="HomeContainer">
    <div class="BoxEdit ImgDiv">
      <img :src="this.HomeData.ImgUrl" alt="">
      <button class="btn btn-primary ChangeButton" @click="this.openWigit"><i class="bi bi-upload"></i>החלף תמונה</button>
      
    </div>
    <div class="BoxEdit InfoDiv">
      <h2>תיאור</h2>
      <p>
        {{ this.HomeData.Info }}
      </p>
      
    </div>
  </div>


</template>

<script>

import axios from 'axios'

export default {
  name: 'HomeEdit',
  props:['HomeData'],
  components: {
  },
  data(){
      return{

      }  
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
    openWigit(){

      const widget = window.cloudinary.createUploadWidget(
        {cloud_name:"dfnusjlhc", upload_preset: "TechLinx"},
        (error,result)=>{
          if(!error && result && result.event == "success"){
            console.log("Done uploading ....",result.info.url)
            this.imgPost = result.info.url      
          }
        }
      )
      widget.open()
    }
  }  

}
</script>

<style scoped>

*{
  margin: 0 ;
  padding: 0 ;
}

.HomeContainer{
  border: 1px solid none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  right: 50%;
  transform: translateX(50%);
}

.BoxEdit{
  position: relative;
  border: 1px solid none;
  width: 310px;
  max-width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
  background: #c0e3ff;  
  padding: 15px;
  gap: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.ImgDiv img{
  border: 1px solid #9fb8cd; 
  position: relative;
  top: 5%;
  right: 50%;
  transform: translate(50%);
  width: 100%;
  height: 200px;
  border-radius: 15px;
  object-fit: contain;
  /* margin: 5%; */
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  box-shadow: rgb(171, 186, 199) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.699) -3px -3px 6px 1px inset;
}

.ChangeButton{
  position: relative;
  width: 90%;
  font-size: .8em;
}

.ChangeButton i{
  padding: 0px 10px 0px 5px;
}

.InfoDiv{
  border:1px solid none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: center;
  border-radius: 15px;
  background: #c0e3ff;  
  padding: 20px 0px 0px 0px;
}

.InfoDiv h2{
  padding: 0px 0px 20px 0px;
  width: fit-content;
  color: #101F38;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 2em;
}

.InfoDiv p{
  overflow: auto;
  padding: 10px;
  color: black;
  text-align: center;
  font-size: 1em;
}


@media (max-width: 750px) {
/* 
  .InfoDiv h2{
    padding: 0px 0px 20px 0px;
    width: fit-content;
    color: #101F38;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1.4em;
  } */

  .BoxEdit{
    width: 90%;
    max-width: 90%;
  }
}
@media (max-width: 400px) {
  .BoxEdit{
    height: 70vw;
  }
}


</style>




